/* Tables */
.table-controls {
  display: flex;
  flex-direction: row-reverse;
}

table.mat-table {
  caption {
    display: none;
  }
  th {
    font-weight: bold !important;
    cursor: default;
  }
  tr {
    height: 40px;
    vertical-align: middle;
    &.mat-mdc-row {
      cursor: pointer !important;
      &:hover {
        background-color: lightgray;
      }
    }
  }
  td {
    font-size: small;
    &.number {
      text-align: right;
    }
  }
}
