.buttons {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
}

.dialog-buttons { 
  margin-top: 10px;
}

.loading {
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  height: 100px;
}

.slider-form-control {
  margin-top: 10px;
  ::ng-deep {
    .mat-slider-thumb-label {
      transform: rotate(45deg) !important;
      border-radius: 50% 50% 0 !important;
    }  
    .mat-slider-thumb {
      transform: scale(0) !important;
    }  
    .mat-slider-thumb-label-text {
      opacity: 1 !important;
    }
  }
}
