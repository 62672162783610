@import "styles/variables";
@import "styles/tables";
@import "styles/framework";

body {
  margin: 0;
}

// TODO: Move this to the typography file in the MIS Component Library
.bold-text {
  font-weight: bold;
}

mis-page, .page {
  padding-bottom: $footerHeight;
}

.button-group {
  & button {
    margin-right: 10px;
  }
}
.center-loading {
  padding: 20px;
  position: absolute;
  left: 50vw;
  top: 40vh;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.confirmation-dialog .mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 16px !important;
}

.confirmation-dialog .mat-mdc-dialog-title {
  color: #333333 !important;
}

.confirmation-dialog .mat-mdc-dialog-content {
  font-size: 12px !important;
  color: #333333 !important;
}

.confirmation-dialog .mat-mdc-dialog-actions {
  justify-content: flex-end !important;
  padding-right: 15px !important;
  padding-bottom: 15px !important;
}

.confirmation-dialog .mat-mdc-button {
  background-color: #2E75B9 !important;
  color: white !important;
  border-radius: 20px !important;
  padding: 0 16px !important;
  min-width: 64px !important;
  margin-left: 8px !important;
}

.confirmation-dialog .mat-mdc-button:hover {
  background-color: #3f51b5 !important;
}